import {
  Button,
  Card,
  Col,
  Image,
  Form,
  Input,
  Row,
  message,
  Empty,
  Modal,
  Alert,
  Tooltip,
  Progress,
  Badge,
  Popconfirm,
} from "antd";
import ProgressBar from "./ProgressBar";
import ProductService from "../../../../services/ProductService";
import { openNotification } from "../../../../utils";
import { IProductResponse } from "../../../../types/Product";
import { useState } from "react";
import InventoryShipmentCard from "./InventoryShipmentCard";
import StarterCard from "../../../Common/StarterCard/StarterCard";
import FbaImg from "../../../../assets/Dashboard/fba-quickaccess.png";
// import { ReactComponent as InfoIcon } from "../../assets/info-icon.svg";
// import OneamzAppService from "../../services/OneamzAppService";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning-icon.svg";
import WarehouseInfoCard from "../../../Common/WarehouseInfoCard/WarehouseInfoCard";
import StorageResultItemCard from "./StorageResultItemCard";
import IncomingInventoryService from "../../../../services/IncomingInventoryService";
// import { IIncomingInventoryResponse } from "../../types/IncomingInventory";
import UserBoxService from "../../../../services/UserBoxService";
import {
  IUserBoxesCheckRequest,
  IUserBoxesPurchaseRequest,
} from "../../../../types/UserBox";

const StorageCreate = () => {
  const [productLoad, setProductLoad] = useState<boolean>(false);
  // const [balanceCheckLoad, setBalanceCheckLoad] = useState<boolean>(false);
  const [userBoxCheckLoad, setUserBoxCheckLoad] = useState<boolean>(false);
  const [userBoxPurchaseLoad, setUserBoxPurchaseLoad] =
    useState<boolean>(false);
  const [orderItemList, setOrderItemList] = useState<IProductResponse[]>([]);
  const [userBoxesCheckData, setUserBoxesCheckData] = useState<any>([]);
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  // eslint-disable-next-line
  // const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [loadBalance, setLoadBalance] = useState<any>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [orderCreateLoading, setOrderCreateLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>([]);
  const [shipmentCode, setShipmentCode] = useState<any>(null);
  const [needsNewBoxes, setNeedsNewBoxes] = useState(false);
  const [requiredNewBoxCount, setRequiredNewBoxCount] = useState(0);
  const [requiredNewBoxFillRate, setRequiredNewBoxFillRate] = useState(0);
  const [currentFillRate, setCurrentFillRate] = useState(0);
  const [asinFind] = Form.useForm();
  const externalToken = localStorage.getItem("externalAccessToken");
  const externalRefreshToken = localStorage.getItem("externalRefreshToken");

  const refreshPage = () => {
    window.location.reload();
  };

  // const getIncomingInventoryDetail = (id: number) => {
  //   IncomingInventoryService.getIncomingInventoriesById(id)
  //     .then((response: any) => {
  //       const data: IIncomingInventoryResponse = new IIncomingInventoryResponse(
  //         response.data
  //       );
  //       setOrder([...order, data]);
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     });
  // };

  const onCreateOrder = () => {
    setOrderCreateLoading(true);
    let postObj = {
      asin: orderItemList[0]?.asin,
      quantity: Number((orderItemList[0] as any)?.qty),
    };

    IncomingInventoryService.createIncomingInventory(postObj)
      .then((response: any) => {
        // const externalUserId = response.data[0].user.externalUserId;
        // const orderId = response.data[0].id;
        // const boxName = response.data[0].box.name;
        // setShipmentCode(`${boxName}-${orderId}-${externalUserId}`);
        const newShipmentCodes = response.data.map((item: any) => {
          const externalUserId = item.user.externalUserId;
          const orderId = item.id;
          const boxName = item.box.name;
          return `${boxName}-${orderId}-${externalUserId}`;
        });
        setShipmentCode(newShipmentCodes);
        setOrder([...order, ...response.data]);

        openNotification("success", "Success", "Warehouse shipment created");
        setOrderCreated(true);
        // getIncomingInventoryDetail(orderId);
      })
      .catch((e: any) => {
        console.log(e);
        openNotification("error", "Error", e.response.data.message);
      })
      .finally(() => setOrderCreateLoading(false));
  };

  const postUserBoxesCheck = () => {
    setUserBoxCheckLoad(true);
    const requestBody: IUserBoxesCheckRequest = {
      asin: orderItemList[0]?.asin,
      quantity: Number((orderItemList[0] as any)?.qty),
    };
    UserBoxService.postUserBoxesCheck(requestBody)
      .then((response: any) => {
        setUserBoxesCheckData(response.data);
        if (response.data.needsNewBoxes === true) {
          setIsConfirmModal(true);
        } else if (response.data.needsNewBoxes === false) {
          onCreateOrder();
        }
      })
      .catch((e: any) => {
        if (
          e.response.data.statusCode === 404 &&
          e.response.data.code === "oneamz-ff-available-boxes-not-found"
        ) {
          openNotification(
            "error",
            "Error",
            "No available boxes in stock or for purchase. Please check warehouse availability or contact support."
          );
        } else {
          openNotification("error", "Error", "User boxes check failed");
        }
        console.log(e);
      })
      .finally(() => setUserBoxCheckLoad(false));
  };

  const postUserBoxesPurchase = () => {
    setUserBoxPurchaseLoad(true);
    const requestBody: IUserBoxesPurchaseRequest = {
      quantity: userBoxesCheckData.requiredBoxCount,
    };
    UserBoxService.postUserBoxesPurchase(requestBody, externalToken ?? "")
      .then((response: any) => {
        onCreateOrder();
      })
      .catch((e: any) => {
        console.log("Error: ", e);
        if (e.response.data.message === "Not enough balance") {
          openNotification(
            "error",
            "Error",
            "Not enough balance to complete the purchase. Please add funds."
          );
          if (userBoxesCheckData.totalCost) {
            setLoadBalance(userBoxesCheckData.totalCost.toFixed(2));
            setIsModalOpen(true);
          }
        } else if (e.response.data.message === "Available boxes not found") {
          openNotification(
            "error",
            "Error",
            "No available boxes in stock or for purchase. Please check warehouse availability or contact support."
          );
        } else {
          openNotification("error", "Error", "User boxes purchase failed");
        }
        console.log(e);
      })
      .finally(() => setUserBoxPurchaseLoad(false));
  };

  // const getBalance = () => {
  //   setBalanceCheckLoad(true);
  //   OneamzAppService.getBalance()
  //     .then((response: any) => {
  //       let currentBalance: number = response.data.balance;
  //       setCurrentBalance(currentBalance);
  //       if (currentBalance < userBoxesCheckData.totalCost) {
  //         setLoadBalance(
  //           (userBoxesCheckData.totalCost - currentBalance).toFixed(2)
  //         );
  //         setIsModalOpen(true);
  //       } else {
  //         postUserBoxesPurchase();
  //       }
  //     })
  //     .catch((e: Error) => {
  //       console.log(e);
  //     })
  //     .finally(() => setBalanceCheckLoad(false));
  // };

  const handleSubmit = (values: any) => {
    setProductLoad(true);
    ProductService.getbyASIN(values.asin)
      .then((response: any) => {
        let data: IProductResponse = response.data;
        if (!orderItemList.some((item) => item.asin === data.asin)) {
          let newData = {
            ...data,
            qty: 1,
            fnskuLabel: null,
            trackingCode: null,
            services: [],
          };
          setOrderItemList([...orderItemList, newData]);
          CheckStorage(newData.asin, newData.qty);
          message.success("Product added");
        } else {
          message.success("Product available in the list");
        }
        asinFind.resetFields();
      })
      .catch((e: Error) => {
        message.error("Product not found");
      })
      .finally(() => setProductLoad(false));
  };

  const onHandleRemoveProduct = (asin: string, id: any) => {
    const updatedList = orderItemList.filter((item: any) => item.id !== id);
    setOrderItemList(updatedList);
    CheckStorage(asin, 0);
    message.success("Product extracted");
  };

  const onHandleProductChange = (updatedItem: any) => {
    setOrderItemList((orderItemList) => {
      return orderItemList.map((item: any) => {
        if (item.id === updatedItem.id) {
          return updatedItem;
        }
        return item;
      });
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const CheckStorage = (asin: string, quantity: number) => {
    UserBoxService.postUserBoxesCheckStorage({
      asin,
      quantity,
    })
      .then((response: any) => {
        const { used, newBox } = response.data;
        setNeedsNewBoxes(newBox.count > 0);
        setRequiredNewBoxCount(newBox.count);
        setRequiredNewBoxFillRate(newBox.totalFillRate);
        setCurrentFillRate(used.totalFillRate);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  return (
    <>
      {!orderCreated ? (
        <>
          <Modal
            title={<h3 style={{ margin: "0px" }}>Insufficient Credit</h3>}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "black",
                      color: "pure-white",
                      padding: "0px 30px",
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_EXTERNAL_BASE_URL}/public/module/callback?accessToken=${externalToken}&refreshToken=${externalRefreshToken}&redirectUrl=/balance?amount%3D${loadBalance}%26openPaymentModal%3Dtrue`}
                  >
                    <Button
                      style={{
                        backgroundColor: "#FFCB2B",
                        color: "black",
                        padding: "0px 20px",
                      }}
                      type="primary"
                    >
                      Upload Credit
                    </Button>
                  </a>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <InfoCircleOutlined
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <h3
              style={{
                textAlign: "center",
                fontWeight: 400,
                margin: "40px 0px",
              }}
            >
              You must have at least <b>${loadBalance}</b> credit in your
              account to complete this transaction.
            </h3>
          </Modal>
          <Modal
            open={isConfirmModal}
            onCancel={() => {
              setIsConfirmModal(false);
            }}
            footer={() => (
              <Row gutter={[24, 24]} align={"middle"} justify={"center"}>
                <Col>
                  <Button
                    className="bg-slate-blue border-radius-8 btn-hover-white btn font-color-cloud btn btn-text"
                    onClick={() => {
                      setIsConfirmModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="bg-oneamz-yellow border-radius-8 btn-hover-dark font-color-slate-blue btn btn-text"
                    loading={
                      userBoxPurchaseLoad ||
                      userBoxCheckLoad ||
                      orderCreateLoading
                    }
                    onClick={() => {
                      postUserBoxesPurchase();
                    }}
                  >
                    Add{" "}
                    {userBoxesCheckData.requiredBoxCount > 1 ? "Boxes" : "Box"}
                  </Button>
                </Col>
              </Row>
            )}
          >
            <Row justify={"center"}>
              <WarningIcon
                style={{
                  fontSize: "48px",
                  marginTop: "20px",
                  color: "#FFCB2B",
                }}
              />
            </Row>
            <Row justify={"center"}>
              <Col span={20}>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px 10px",
                  }}
                >
                  <b>Number of Storage Boxes to Add:</b>{" "}
                  {userBoxesCheckData.requiredBoxCount}
                </h3>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px",
                  }}
                >
                  You need to purchase{" "}
                  <b>{userBoxesCheckData.requiredBoxCount}</b> new{" "}
                  {userBoxesCheckData.requiredBoxCount > 1 ? "boxes" : "box"}{" "}
                  for <b>{userBoxesCheckData.itemsNeedingNewBox}</b> out of the{" "}
                  <b>{Number((orderItemList[0] as any)?.qty)}</b>, items you
                  wish to add. The total amount you need to pay is{" "}
                  <b>${userBoxesCheckData.totalCost}</b>.
                </h3>
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: 400,
                    margin: "40px 0px",
                  }}
                >
                  <b>Note:</b> These payments are monthly per box.
                </h3>
              </Col>
            </Row>
          </Modal>
          <Row>
            <Col span={24}>
              <ProgressBar currentStep={0} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card className="border-radius-md border-slate-blue-02 mt-24">
                <h3 className="h-3 font-color-slate-blue mt-0">
                  Send Products to Warehouse with ASIN Code!
                </h3>
                <p className="text fs-14">
                  Our shipped products are stored quickly and in an organized
                  manner in accordance with quality standards. Our warehouse is
                  equipped with effective stock management so that customer
                  orders can be processed quickly and smoothly. After going
                  through a secure storage process, our products are quickly
                  made ready for shipment, which ensures timely transportation
                  to customers.
                </p>
                <Form form={asinFind} onFinish={handleSubmit}>
                  <Row className="mt-24">
                    <Col>
                      <Form.Item
                        className="mb-0"
                        name="asin"
                        rules={[
                          {
                            required: true,
                            message: "Enter ASIN code",
                          },
                        ]}
                      >
                        <Input
                          className="amz-input-44px"
                          placeholder="ASIN..."
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        htmlType="submit"
                        loading={productLoad}
                        className="ml-24 btn btn-hover-dark bg-slate-blue font-color-cloud btn-text"
                        disabled={productLoad || orderItemList.length >= 1}
                      >
                        Add
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Alert
                className="border-none border-radius-md mt-24"
                description={
                  <p className="text fs-14 m-0">
                    <b>
                      *Please use the following address information in your
                      order
                    </b>
                    <br />
                    <br />
                    <b>OneAmz Warehouse Address: </b> 356 GETTY AVE BLD-5A #
                    ONEAMZ-0413464 CLIFTON, NJ 07011-2118 United States <br />
                    <b>Phone: </b> 917 306 6033
                  </p>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <Card className="bg-cloud border-radius-md">
                <Row>
                  <Col span={24}>
                    <Card className="bg-white border-radius-md">
                      <Row gutter={[32, 32]}>
                        <Col md={16} sm={12} xs={12}>
                          <p
                            style={{ textAlign: "center" }}
                            className="font-color-slate-blue-06 fs-18"
                          >
                            Used{" "}
                            <b className="font-color-slate-blue">
                              {currentFillRate.toFixed(0)}%
                            </b>{" "}
                            Storage Box
                          </p>
                          <Tooltip
                            placement="bottom"
                            title={<b>{currentFillRate.toFixed(0)}%</b>}
                          >
                            <Progress
                              className="storage-progress"
                              size={["100%", 20]}
                              percent={currentFillRate}
                              showInfo={false}
                            />
                          </Tooltip>
                        </Col>
                        <Col md={8} sm={12} xs={12}>
                          <p
                            style={{ textAlign: "center" }}
                            className="font-color-slate-blue-06 fs-18"
                          >
                            Added{" "}
                            <b className="font-color-slate-blue">
                              {needsNewBoxes ? `+${requiredNewBoxCount}` : 0}
                            </b>{" "}
                            Storage Box
                          </p>
                          <Tooltip
                            placement="bottom"
                            title={
                              <b className="font-color-slate-blue">
                                {requiredNewBoxFillRate.toFixed(0)}%
                              </b>
                            }
                            color={"#FFCB2B"}
                          >
                            <Progress
                              className="product-storage-progress"
                              size={["100%", 20]}
                              percent={requiredNewBoxFillRate}
                              showInfo={false}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row className="mt-16">
                        <Badge
                          color="#FFCB2B"
                          text="Used Storage Box"
                          className="mr-24"
                        />
                        <Badge
                          color="#333E48"
                          text="Space required for storage of new products"
                        />
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-24">
                  {orderItemList.map((item, key) => (
                    <Col span={24} key={key}>
                      <InventoryShipmentCard
                        changeProduct={onHandleProductChange}
                        removeProduct={onHandleRemoveProduct}
                        item={item}
                        checkStorage={CheckStorage}
                      />
                    </Col>
                  ))}
                  {orderItemList.length === 0 ? (
                    <Col span={24}>
                      <Empty
                        description={
                          <h3>Add product to warehouse shipping list</h3>
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="mt-24" justify={"space-between"}>
            <Col>
              <Button className="btn btn-hover-dark border-radius-12 bg-cloud btn-text font-color-slate-blue">
                Back
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title="Are you sure you want to send this to the warehouse?"
                onConfirm={postUserBoxesCheck}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={orderItemList.length <= 0}
                  loading={userBoxCheckLoad}
                  className="btn btn-hover-white border-radius-12 bg-slate-blue btn-text font-color-cloud"
                >
                  Send to Warehouse
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Card
            className="mt-24"
            bodyStyle={{ border: "none", padding: "0px" }}
            style={{ border: "none" }}
          >
            {order.map((item: any, index: number) => (
              <Row gutter={[32, 32]} key={index} className="mb-24">
                <Col xxl={6} sm={9} xs={24}>
                  <Card
                    className="bg-oneamz-yellow border-radius-md"
                    style={{ minHeight: "100%" }}
                  >
                    <p
                      className="text font-color-slate-blue bg-cloud p-8 border-radius-sm m-0"
                      style={{ textAlign: "center" }}
                    >
                      <b>WAREHOUSE SHIPPING CODE</b>
                    </p>

                    <h2
                      className="h-3 font-color-slate-blue mt-16 mb-0 p-8"
                      style={{ textAlign: "center" }}
                    >
                      ({shipmentCode[index]})
                    </h2>
                    <p className="text fs-18 font-color-slate-blue mt-24">
                      To access the details of your product, you can search
                      within the app with the oneship shipping code
                    </p>
                    <Row justify={"center"} className="mt-64">
                      <Col>
                        <Image preview={false} src={FbaImg} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xxl={8} sm={15} xs={24}>
                  <Card
                    className="bg-cloud border-radius-md"
                    style={{ minHeight: "100%" }}
                  >
                    <h3 className="h-3 font-color-slate-blue m-0">
                      Congratulations, you have completed Step 1!
                    </h3>
                    <p className="text fs-24 font-color-slate-blue mt-8">
                      Your shipment request has been successfully registered
                    </p>
                    <p className="text font-color-slate-blue mt-8">
                      Thank you for your care and attention. You have
                      successfully completed this step of your warehouse
                      fulfillment process. You can complete Step 2 and finalize
                      your warehouse fulfillment process.
                    </p>
                    <p className="pretitle font-color-slate-blue mt-32 upper-case">
                      PRODUCT INFORMATION
                    </p>
                    <StorageResultItemCard item={item} key={index} />
                  </Card>
                </Col>
                <Col xxl={10} xs={24}>
                  <Card className="bg-cloud border-radius-md">
                    <Row gutter={36}>
                      <Col span={24}>
                        <h3 className="h-3 m-0 font-color-slate-blue mb-0">
                          Step 2: Send Products to Warehouse
                        </h3>
                        <p className="text font-color-slate-blue mt-8">
                          We expect you to send the products you have specified
                          above to the OneAmz Warehouse address below.
                        </p>
                        <Alert
                          className="border-none border-radius-md my-12"
                          description={
                            <p className="text fs-14 m-0">
                              <b>IMPORTANT INFORMATION:</b> Please note that
                              your order will not be completed unless you send
                              the products to the address below.
                            </p>
                          }
                          type="info"
                          showIcon
                        />
                      </Col>
                      <Col span={24}>
                        <WarehouseInfoCard shipmentCode={shipmentCode[index]} />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            ))}

            <Row justify={"end"} gutter={8}>
              <Col>
                <Button
                  onClick={() => refreshPage()}
                  className="btn btn-text btn-hover-white bg-slate-blue font-color-cloud border-radius-12 mt-24"
                >
                  Pull New Product(s) to Warehouse
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      )}
    </>
  );
};

export default StorageCreate;
