import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import StarterCard from "../../components/Common/StarterCard/StarterCard";
import AdminStarterCard from "../../components/Common/StarterCard/AdminStarterCard";
import QuickAccess from "../../components/Dashboard/QuickAccess/QuickAccess";
import Graph from "../../components/Common/Graph/Graph";
import ShipmentList from "../../components/Dashboard/ShipmentList/ShipmentList";
import TotalProducts from "../../components/Dashboard/AdminDashboard/TotalProducts";
import IncompleteFBA from "../../components/Dashboard/AdminDashboard/IncompleteFBA";
import IncompleteFBM from "../../components/Dashboard/AdminDashboard/IncompleteFBM";
import WaitingProducts from "../../components/Dashboard/AdminDashboard/WaitingProducts";
import SummaryService from "../../services/SummaryService";
import { ISummaryResponse } from "../../types/Summary";
import "./dashboard.scss";

const Dashboard = () => {
  const [role, setRole] = useState<string | null>(null);
  // const [storageLevel, setStorageLevel] = useState<number>(0);
  const [productCountLoading, setProductCountLoading] = useState(false);
  const [summary, setSummary] = useState<ISummaryResponse | null>(null);

  useEffect(() => {
    const storedRole = localStorage.getItem("userRole");
    // setStorageLevel(Number(localStorage.getItem("storageLevel") || 0));
    setRole(storedRole);
    // eslint-disable-next-line
  }, []);

  const getSummary = () => {
    setProductCountLoading(true);
    SummaryService.getSummary()
      .then((response) => {
        setSummary(response.data);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setProductCountLoading(false));
  };

  useEffect(() => {
    getSummary();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="dashboard">
      {role === "CUSTOMER" ? (
        <>
          <Row>
            <Col span={24}>
              <StarterCard />
            </Col>
          </Row>
          <Row className="mt-24" gutter={[24, 24]}>
            <Col xl={16} lg={24} md={24} sm={24}>
              <QuickAccess />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24}>
              <Graph />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <ShipmentList />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row>
            <Col span={24}>
              <AdminStarterCard />
            </Col>
          </Row>
          <Row className="mt-24" gutter={[24, 24]}>
            <Col xl={6} md={12} sm={12} xs={24}>
              <TotalProducts loading={productCountLoading} value={0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <IncompleteFBA loading={productCountLoading} value={summary?.fbaOrderCount || 0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <IncompleteFBM loading={productCountLoading} value={summary?.fbmOrderCount || 0} />
            </Col>
            <Col xl={6} md={12} sm={12} xs={24}>
              <WaitingProducts loading={productCountLoading} value={summary?.warehouseCount || 0} />
            </Col>
          </Row>
          <Row className="mt-24">
            <Col span={24}>
              <ShipmentList />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Dashboard;
